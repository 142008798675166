import React from 'react';
import PropTypes from 'prop-types';


const AccountStatus = ({ children, timeline}) => {  
  return (
    <div className={'text-base font-bold text-primary-900'}>{children}<br/><span className={'text-xs font-semibold text-text-black '}>{timeline}</span></div>
  );
};
               
export default AccountStatus;

AccountStatus.propTypes = {
  children: PropTypes.string,
  timeline: PropTypes.string,
};