import React from 'react';
import PropTypes from 'prop-types';


const Comment = ({className, children}) => {
     
  return (
    <div className={className}>{children}</div>
  );
};
               
export default Comment;

Comment.propTypes = {
  children: PropTypes.string,
  className:PropTypes.string
};