export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const preventSpecialChars = (event) => {
  const value = event.target.value;
  event.target.value = value.replace(/[^a-zA-Z\s]/g, ""); // Allows only letters and spaces
};

export const allowOnlyNumbers = (event) => {
  const value = event.target.value;
  event.target.value = value.replace(/[^0-9]/g, ""); // Allows only letters and spaces
};