export const Webengage = {
  USER_SIGNEDUP: "User Signed Up",
  NEW_USER_SIGNEDUP: "User Signed Up",
  USER_LOGGED_IN: "User Logged In",
  NEW_USER_LOGGED_IN: "User Logged In",
  PARENT_PROFILE_CREATION: "Parent Profile Created",
  SESSION_STARTED: "session_started",
  SCHOOL_NOT_FOUND: "School Not Found",
  NEW_SCHOOL_ADD_REQUEST: "New School onboard Request",
  NEW_KID: "New Kid",
  NEW_KID_FLOW_COMPLETED: "New Kid Flow Completed",
  LOAD_MENU: "Load Menu",
  ADDED_TO_CART: "Added to cart",
  REMOVED_FROM_CART: "Removed From Cart",
  CART_VIEWED: "Cart Viewed",
  NEW_CART_VIEWED: "Cart Viewed",

  CART_UPDATED_ADDED: "Cart Updated Added",
  CART_UPDATED_REMOVED: "Cart Updated Removed",
  CHECKOUT_STARTED: "Checkout Started",
  CHECKOUT_COMPLETED: "Checkout Completed",
  PRODUCT_VIEWED: "Product Viewed",
  ORDER_CANCELLED: "Order Cancelled",
  // ADD_ON_PAYMENT_INITIATED: "Add On Payment Initiated",
  PAYMENT_INITIATED: "Payment Initiated",
  PAYMENT_INITIATED_NEW: "Payment Initiated",

  PAYMENT_FAILURE: "Payment Failure",
  WALLET_RECHARGE: "Wallet - Recharge",
  SKIP_AND_VIEW_MENU: "Skip and View Menu",
  VIEW_OTHER_PLANS: "View Other Plans",
  VIEW_PLAN_DESCRIPTION: "View Plan description",
  NEW_VIEW_PLAN_DESCRIPTION: "View Plan description",
  PLAN_SUBSCRIBE_START: "Plan Subscribe Start",
  NEW_PLAN_SUBSCRIBE_START: "Plan Subscribe Start",
  PLAN_CHECKOUT_INITIATED: "Plan Checkout Initiated",
  NEW_PLAN_CHECKOUT_INITIATED: "Plan Checkout Initiated",
  PLAN_CHECKOUT_SUCCESSFULL: "Plan Checkout Successful",
  NEW_PLAN_CHECKOUT_SUCCESSFULL: "Plan Checkout Successful",
  PLAN_CHECKOUT_FAILED: "Plan Checkout Failed",
  NEW_PLAN_CHECKOUT_FAILED: "Plan Checkout Failed",
  MEAL_ITEM_SELECTED: "Item Selected",
  MEAL_ITEM_SELECTED: "Subscription Menu Item Selected",
  UPGRADE_ADDED_TO_CART: "Upgrade Added To Cart",
  ADD_ON_PAYMENT_SUCCESSFULL: "Add On Payment Successful",
  ADD_ON_PAYMENT_FAILED: "Add On Payment Failed",
  ADD_ON_PAYMENT_INITIATED: "Add On Payment Initiated",
  ADD_ON_ADDED_TO_CART: "Add-On Added To Cart",
  ADD_ON_REMOVED_FROM_CART: "Add-On Removed From Cart",
  PLACE_ORDER: "Place Order",
  PLAN_MEAL_ORDERED: "Plan Meal Ordered",
  CANCEL_ORDER: "Cancel order",
  NEW_CANCEL_ORDER: "Cancel order",
  CANCEL_MEAL_FOR_THE_DAY: "Cancel meal for the day",
  NEW_CANCEL_MEAL_FOR_THE_DAY: "Cancel meal for the day",
  REORDER_MEAL_FOR_THE_DAY: "Reorder meal for the day",
  NEW_REORDER_MEAL_FOR_THE_DAY: "Reorder meal for the day",
  CART_AND_CHECKOUT: "Cart and Checkout",
  VIEW_FAQ: "View FAQ",
  CASHBACK_RECEIVED: "Cashback Recieved",
  CHILD_DETAILS_UPDATED: "Child Details Updated",
  NEW_CHILD_DETAILS_UPDATED: "Child Details Updated",
  SCHOOL_DETAILS_UPDATED: "School Details Updated",
  PROFILE_SWITCHED: "Profile Switched",
  NEW_PROFILE_SWITCHED: "Profile Switched",
  NEW_PRIVACY_POLICY:"Privacy Policy",
  NEW_TERMS_AND_CONDITION:"Terms and Condition",
  VIEW_SIDEBAR: "Veiw Sidebar",
  ADD_ANOTHER_CHILD: "Add Another Child",
  DELETE_ACCOUNT: "Delete Account",
  PARENT_DETAILS_UPDATED: "Parents Details Updated",
  VEIW_REFER_EARN: "view Refer And Earn",
  REER_CODE_COPIED: " Refer And Earn Code Copied",
  VIEW_REFERRAL_HISTORY: " View Refer History",
  VEIW_HELP: " View Help",
  HELP_CALL: "Help  Call",
  HELP_MAIL: "Help  Mail",
  VEIW_ABOUT: "Veiw About ",
  VEIW_PRIVACY_POLICY_SIDEBAR: "Veiw Privacy Policy Sidebar ",
  VEIW_TERMS_SIDEBAR: "Veiw Terms and Conditions  Sidebar ",
  USER_LOGOUT: "User Logout ",
  VIEW_ORDERS: "View Order Details ",
  ORDER_TRACK: "Order Details View ",
  VIEW_ACTIVE_ORDERS: "Active Order ",
  VIEW_PAST_ORDERS: "Past Order ",
  EDIT_ORDER: "Edit Order ",
  FEEDACK_SUCCESS: "Feedback Success",
  VIEW_PROFILE: "View Profile",
  VIEW_SUBSCRIPTIONS: "View Subscriptions",
  VIEW_SUBSCRIPTIONS_HISTORY: "View Subscription History",
  VIEW_PLANS: "View Plans",
  NEW_SUBSCRIPTION_CANCEL: "Cancel Subscription",

  PROMOCODE_APPLY_SUBSCRIPTION: "Promo Code Applied Subscription",

  PROMOCODE_REMOVE_SUBSCRIPTION: "Promo Code Removed Subscription",
  
  NEW_SUBSCRIPTION_CANCEL_FAIL: "Subscription Cancellation Fail",

  NEW_SUBSCRIPTION_CANCEL_POLICY: "Subscription Cancellation Policy",
  OPEN_HOME: "View Menu",
  VIEW_WALLET_HISTORY: "View Wallet History",
  TRANSFER_WALLET_TO_KID: "Transfer Amount To Kid",
  REFUND_TO_SOURCE: "Transfer Amount To Bank",
  VIEW_HOME_BANNER: "View Home Banner",
  VIEW_ORDER_TRACK_HOMEPAGE: "View Order Track On Homepage",
 PLACE_ORDER_START: "Place Order CTA",
 ADD_MORE_ITEMS: "Add More Items",
 EDIT_CART_UPDATED: "Edit Cart Updated",
 EDIT_ORDERS_Placed: "Edit Order Placed",
 EDIT_ADD_ON_PAYMENT_INITIATED: "Edit Ad on Payment Initiated",
 EDIT_ADD_ON_PAYMENT_SUCCESSFULL: "Edit Ad on Payment SuccesFull",
 
 EDIT_ADD_ON_PAYMENT_FAILED: "Edit Ad on Payment Failed",
};

