import React from 'react'

const PrimaryGreenButton = ({style="w-full",cta="",onClick}) => {
  return (
    <button className={`bg-[#029769] h-[54px] rounded-[8px] border border-[#029769] flex justify-center items-center text-center text-[14px] text-[#fff] font-semibold
     profilebgshadow hover:bg-[#F3FAF7] hover:text-[#029769] ease-linear duration-500 ${style}`} onClick={onClick}>
    
    {cta}
   </button>
  )
}

export default PrimaryGreenButton