import { useState } from 'react';
import PropTypes from 'prop-types';

const TextArea = ({ register, error, label, id, ...inputProps }) => {
  const [labelTop, setLabelTop] = useState(false);
  const [focused, setFocued] = useState(false);
  return (
    <div className="relative w-full flex flex-col">
      <label
        className={`${labelTop ? 'labelTop' : ''
        } text-text-black text-sm select-none font-normal ${focused && error ? 'text-error' : ''
        } ${focused ? 'text-primary' : ''}`}
      >
        {label}{inputProps.require ? <span className="pl-1 text-semantic-error">*</span>:''}
      </label>
      <textarea
        {...register(id)}
        id={id}
        {...inputProps}
        onFocus={() => {
          setLabelTop(true);
          setFocued(true);
        }}
        onBlur={(e) => {
          e.target.value.length === 0 && setLabelTop(false);
          setFocued(false);
        }}
        placeholder={inputProps.placeholder}
        className={`px-4 py-4 rounded-lg mt-2.5 border border-slate-gray text-primary-900 font-semibold ${inputProps.disabled ? 'bg-gray-100': ''} focus:outline-none focus:ring-2 focus:ring-slate-gray ${error ? 'outline-red' : ''
        } ${inputProps.className} `}
      />
      {error && (
        <div className="text-semantic-error text-xs font-semibold absolute top-32 bottom-2">
          {error.message}
        </div>
      )}
    </div>
  );
};
TextArea.propTypes = {
  register: PropTypes.func.isRequired,
  error: PropTypes.object,
  label: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  require: PropTypes.bool,
};
export default TextArea;
