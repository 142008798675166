import { appWithTranslation } from "next-i18next";
import nextI18NextConfig from "../next-i18next.config.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import WithAuth from "components/Auth/WithAuth";
import { Provider } from "react-redux";
import store from "@/components/store";
import { getLayout } from "components/Layout/LayoutConfiguration";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { Router } from "next/router";
import NProgress from "nprogress";
import "nprogress/nprogress.css"; // Import the CSS for NProgress
import "../styles/globals.css";

// _app.js or _app.tsx
// import 'swiper/swiper-bundle.min.css';

library.add(fas, far);

function MyApp({ Component, pageProps, router }) {
  let layout = getLayout(router.pathname);
  let persistor = persistStore(store);
  // useEffect(() => {
  //   Router.events.on("routeChangeStart", () => NProgress.start());
  //   Router.events.on("routeChangeComplete", () => NProgress.done());
  //   Router.events.on("routeChangeError", () => NProgress.done());

  //   return () => {
  //     Router.events.off("routeChangeStart", () => NProgress.start());
  //     Router.events.off("routeChangeComplete", () => NProgress.done());
  //     Router.events.off("routeChangeError", () => NProgress.done());
  //   };
  // }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <WithAuth router={router}>
          {layout({ children: <Component {...pageProps} /> })}
        </WithAuth>
      </PersistGate>
    </Provider>
  );
}
export default appWithTranslation(MyApp, nextI18NextConfig);
