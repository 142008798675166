import React from 'react'

const SecondaryGreenBUtton = ({style="w-full h-[54px]",cta="",onClick}) => {
  return (
    <button className={` bg-[#F3FAF7]  rounded-[8px] border border-[#029769] flex justify-center items-center text-center text-[14px] text-[#029769] font-semibold profilebgshadow hover:bg-[#029769] hover:text-white ease-linear duration-500 ${style}`} onClick={onClick}>
    {cta}
    </button>
  )
}

export default SecondaryGreenBUtton