import React from 'react';
import PropTypes from 'prop-types';


const Title = ({ children}) => {
     
  return (
    <div className={'font-bold font-Quicksand text-2xl text-primary-black'}>{children}</div>
  );
};
               
export default Title;

Title.propTypes = {
  children: PropTypes.string,
};       