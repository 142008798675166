import Spinner from "./Spinner";
import Text from "./Text";

const Modal = ({
  heading,
  onCancel,
  content,
  onClick,
  cta,
  subContent,
  checkToday,
  btnLoading,
}) => {
  return (
    <div className="bg-black bg-opacity-50 fixed inset-0 z-50 w-full h-full  flex justify-center items-center px-1">
      <div className="bg-gray-200 max-w-sm md:max-w-md py-6 px-8 rounded-2xl shadow-xl text-gray-800">
        <div className="flex justify-between items-center">
          <h4 className="text-lg font-bold">{heading}</h4>
          <svg
            className="h-6 w-6 cursor-pointer p-1 hover:bg-gray-300 rounded-full"
            fill="currentColor"
            viewBox="0 0 20 20"
            onClick={onCancel}
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <div className="mt-3 text-md">
          <p>{content}</p>
          {checkToday
            ? subContent?.orderStatus && (
                <div className="mt-6">
                  <Text className="font-bold">Refund details:</Text>
                  <ul className="space-y-4 mt-2">
                    {subContent?.orderStatus.map((obj) => {
                      return (
                        <li>
                          <div className="flex justify-between">
                            <Text className="font-semibold">
                              {obj.order.Menu.name}
                            </Text>
                            <Text className="font-semibold">
                              {obj.order?.subscriptionId
                                ? "Part of the meal"
                                : "₹" + obj.refundAmount / 100}
                            </Text>
                          </div>
                          <Text className="text-start">
                            {obj.message || ""}
                          </Text>
                          <div className="h-[1px] bg-rose-300"></div>
                        </li>
                      );
                    })}
                    <Text className="font-semibold text-center text-lg">
                      Total Refund: ₹ {subContent?.totalRefund / 100}
                    </Text>
                  </ul>
                </div>
              )
            : ""}
        </div>
        <div className="mt-6 flex justify-end space-x-3">
          <button
            className="px-3 py-1 rounded hover:bg-red-300 hover:bg-opacity-50 hover:text-red-900"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className={`${
              btnLoading
                ? "bg-primary-200 opacity-100 text-primary-700 pointer-events-none"
                : "bg-primary-900 text-white hover:bg-primary-900"
            } px-3 text-sm  py-2 rounded-full min-w-[80px]`}
            onClick={onClick}
          >
            {cta}
            {btnLoading ? (
              // <div className="ml-4 w-5 h-5 border-b-2 border-black-900 rounded-full animate-spin"></div>
              <Spinner width="5" height="5" />
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
