import { useState, useRef, useEffect } from "react";
import Header from "organisms/Header";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";
import ProfileDropDown from "../../organisms/ProfileDropDown";
import { capitalizeFirstLetter } from "services/helpers.service";
import { switchKidAction } from "../store/reducers/switchKid";
import ProfileDropDownB2B from "organisms/ProfileDropDownB2B";
import moment from "moment";
import { Webengage } from "constants/webengage";
import { getAuth } from "services/identity.service";
import ProfileDropDownSchoolB2B from "organisms/ProfileDropDownSchoolB2B";
import { getSettings } from "services/referral.service";
import { getSubscriptionDetails } from "services/plans.service";
import NewHeader from "organisms/NewHeader";
import NewProfileDropDownV3 from "organisms/NewProfileDropDownV3";
import ContactUsModal from "../NewAddChild/ContactUsModal";
import AboutModalV3 from "molecules/AboutModalV3";
import LogoutConfirm from "molecules/LogoutConfirm";
const R = require("ramda");
import { checisReferralActive, removeAuth } from "services/identity.service";

const NewHeaderComponent = ({ kidList, settings, token }) => {
  const [selectedDate] = useState(moment().format("YYYY-MM-DD"));
  const [OpenContactUs, setOpenContactUs] = useState(false);
  const [AboutModal, setAboutModal] = useState(false);
  const [isLogout, setIsLogout] = useState(false);

  const router = useRouter();
  const auth = getAuth();

  console.log(kidList, "kid list in header component");
  
  const kid = kidList.find((item) => {
    return item.id === router.query.id;
  });
  console.log("kidkidkidkid", kid);
  const findOtherKids = kidList?.filter((item) => {
    return item.id !== router.query.id;
  });

  const selectedKid = useSelector((state) => state.selectedKid);
  const dispatch = useDispatch();
  const profileDropDown = useRef(null);
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [selectedKidId, setSelectedKidId] = useState(null);
  // console.log("headerheader", selectedKid.details.id);
  console.log("selectedKid.details", selectedKid);
  useEffect(async () => {
    if (kid !== undefined) {
      dispatch(
        switchKidAction.updateSelectedKid({
          id: kid.id,
          fullName: capitalizeFirstLetter(kid.fullName),
          photo: kid.photo,
        })
      );
    }
  }, []);

  const handleClickOutside = (event) => {
    setDropdownStatus(false);
  };

  const handleKidSelect = (kidDetails) => {
    console.log(kidDetails, "kidDetails-");
    setSelectedKidId(kidDetails.id); // Set selected kid ID in state
    dispatch(
      switchKidAction.updateSelectedKid({
        id: kidDetails.id,
        fullName: capitalizeFirstLetter(kidDetails.fullName),
        photo: kidDetails.photo,
      })
    );
    
    // Navigate to the new path
    router.push(`/child/${kidDetails.id}/menus`).then(() => {
      // Force page reload to refresh content
      router.replace(`/child/${kidDetails.id}/menus`);
    });
  
    window.webengage.track(Webengage.NEW_PROFILE_SWITCHED, {
      ParentId: kidDetails.parentId,
      oldKidId: router.query.id,
      NewKidId: kidDetails.id,
      NewKidName: kidDetails.fullName,
      Date: moment().format("LLL"),
    });
  };
  
  const handleVeiwReferAndEarnPage = () => {
    
    router.push(`/child/${selectedKid.details.id}/refer-and-earn`);
    window.webengage.track(Webengage.VEIW_REFER_EARN, {
      KidId: kid?.id,
      Date: moment().format("LLL"),
    });
  };

const handelhelp =()=>{
  window.webengage.track(Webengage.VEIW_HELP, {
    KidId: kid?.id,
    Date: moment().format("LLL"),
  });
  setOpenContactUs(true)
}
const handelClickMail =()=>{
  window.webengage.track(Webengage.HELP_MAIL, {
    KidId: kid?.id,
    Date: moment().format("LLL"),
  });
}
const handelClickCall =()=>{
  window.webengage.track(Webengage.HELP_CALL, {
    KidId: kid?.id,
    Date: moment().format("LLL"),
  });
}
const handelClickAbout =()=>{
  window.webengage.track(Webengage.VEIW_ABOUT, {

    Date: moment().format("LLL"),
  });
  setAboutModal(true)
}
const handelPrivacyPolicyView =()=>{
  window.webengage.track(Webengage.VEIW_PRIVACY_POLICY_SIDEBAR, {
    KidId: kid?.id,
    Date: moment().format("LLL"),
  });
  window.open('https://mbbrocure.s3.ap-south-1.amazonaws.com/MonkeyBox+Privacy+Policy.pdf', '_blank');
}
const handelTermsiew =()=>{
  window.webengage.track(Webengage.VEIW_TERMS_SIDEBAR, {
    Date: moment().format("LLL"),
  });
  window.open('https://order.monkeybox.in/terms-and-conditions', '_blank');
}

const handelLogout =()=>{
  window.webengage.track(Webengage.USER_LOGOUT, {
    Date: moment().format("LLL"),
  });
  removeAuth();
  router.push("/login");
}
const viewOrders =()=>{
  window.webengage.track(Webengage.VIEW_ORDERS, {
    KidId: kid?.id,
    Date: moment().format("LLL"),
  });
  router.push(`/child/${selectedKid.details.id}/Order-Details`);
}
const viewSubscription =()=>{
  window.webengage.track(Webengage.VIEW_SUBSCRIPTIONS, {
    KidId: kid?.id,
    Date: moment().format("LLL"),
  });
  router.push({
    pathname: `/child/${selectedKid.details.id}/subscriptions`,
    query: {
      parentId: auth?.id,
    },
  });
}

const viewProfile = () =>{
  router.push(`/child/${selectedKid.details.id}/account-settings`);
  window.webengage.track(Webengage.VIEW_PROFILE, {
    KidId: kid?.id,
    Date: moment().format("LLL"),
  });
}
const addAnotherChild = () =>{
  window.webengage.track(Webengage.ADD_ANOTHER_CHILD, {
    parentId: auth?.id,
    Date: moment().format("LLL"),
  });
  router.push(`/children/add-child`);
}
const OpenMenu = () =>{
  window.webengage.track(Webengage.OPEN_HOME, {
    parentId: auth?.id,
    Date: moment().format("LLL"),
  });
  router.push(`/child/${selectedKid.details.id}/menus`);
}

  const isB2B = router.query.isB2B;
  const { pathname } = router;
  const isMatchingPathForHome = pathname.includes("/menus");
  const isMatchingPathForOrder = pathname.includes("/Order-Details");
  const isMatchingPathForSubscriptions = pathname.includes("/subscriptions");
  const isMatchingPathForProfile = pathname.includes("/account-settings");
  return (
    <>
      {dropdownStatus &&
        (isB2B ? (
          <div ref={profileDropDown}>
            <ProfileDropDownB2B
              src="/images/kidavtar.png"
              setDropdownStatus={setDropdownStatus}
              dropdownStatus={dropdownStatus}
              handleClickOutside={handleClickOutside}
              viewProfile={() => {
                router.push(
                  `/child/${selectedKid.details.id}/account-settings`
                );
              }}
              accountSetting={() => {
                router.push("/account-details");
                
              }}
              kidList={kidList}
              handleKidSelect={handleKidSelect}
            />
          </div>
        ) : auth.roles.includes("Parent Offline B2B") ||
          auth.roles.includes("Parent B2B2C") ? (
          <div ref={profileDropDown}>
            <ProfileDropDownSchoolB2B
              src="/images/kidavtar.png"
              setDropdownStatus={setDropdownStatus}
              dropdownStatus={dropdownStatus}
              handleClickOutside={handleClickOutside}
              viewProfile={() => {
                router.push(
                  `/child/${selectedKid.details.id}/account-settings`
                );
              }}
              accountSetting={() => {
                router.push("/account-details");
              }}
              kidList={kidList}
              handleKidSelect={handleKidSelect}
            />
          </div>
        ) : (
          <div ref={profileDropDown}>
            <NewProfileDropDownV3
              src="/images/kidavtar.png"
              setDropdownStatus={setDropdownStatus}
              openhelpModal={handelhelp}
              openAbout={handelClickAbout}
              onLogoutClick={() => setIsLogout(true)}
              HandelReferAndEarn={handleVeiwReferAndEarnPage}
              AddaChild={addAnotherChild}
              selectedKidId={selectedKidId}
              dropdownStatus={dropdownStatus}
              handleClickOutside={handleClickOutside}
              id={selectedKid.details.id}
              viewProfile={() => {
                router.push(
                  `/child/${selectedKid.details.id}/account-settings`
                );
              }}
              
              accountSetting={() => {
                router.push(
                  `/child/${selectedKid.details.id}/account-details`
                );
              }}
              kidList={kidList}
              handleKidSelect={handleKidSelect}
              SelectekidId={kid?.id}
            />
          </div>
        ))}
      <NewHeader
        isMatchingPathForHome={isMatchingPathForHome}
        isMatchingPathForOrder={isMatchingPathForOrder}
        isMatchingPathForSubscriptions={isMatchingPathForSubscriptions}
        isMatchingPathForProfile={isMatchingPathForProfile}
        isB2B={isB2B}
        kidList={kidList}
        kid={kid}
        selectedKid={selectedKid}
        kidName={selectedKid.details.fullName}
        photo={selectedKid.details.photo}
        otherKid={findOtherKids?.[0]}
        kidId={selectedKid?.details?.id}
        orderHref={`/child/${selectedKid.details.id}/orders`}
        reportHref={
          auth?.roles?.includes("Parent B2B2C")
            ? `/school-b2b2c/${router.query?.id}/report`
            : `/school/${router.query?.id}/report`
        }
        // orderHref={"orders"}

        planSetting={viewProfile}
        showChildListAndprofile={() => {
          router.push(`/children/YourKids`);
        }}
        goToAccountDetailspage={() => {
          router.push(`/child/${selectedKid.details.id}/parent-info`);
        }}
        showChildList={() => {
          router.push(`/children/AllKids`);
        }}
        viewPlans={viewSubscription}
        settings={settings}
        setDropdownStatus={setDropdownStatus}
        dropdownStatus={dropdownStatus}
        goToHomePage={OpenMenu}
        goToprofilePage={() => {
          router.push(`/child/${selectedKid.details.id}/account-settings`);
        }}
        onLogoClick={() => {
          router.push(`/child/${selectedKid.details.id}/menus`);
        }}
        viewOrdeDetails={viewOrders}
      />
      {OpenContactUs && (
        <ContactUsModal handelClose={() => setOpenContactUs(false)} handelClickMail={handelClickMail} handelClickCall={handelClickCall}/>
      )}
      {AboutModal && <AboutModalV3 handelClose={() => setAboutModal(false)} handelPrivacyPolicyView={handelPrivacyPolicyView} handelTermsiew={handelTermsiew}/>}
      {isLogout && (
        <LogoutConfirm
          handelClose={() => setIsLogout(false)}
          onClick={handelLogout}
        />
      )}
    </>
  );
};
// isAutoOrderActive=false&date=2023-08-02
export default NewHeaderComponent;
