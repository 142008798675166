import React, {useState} from 'react';
import PropTypes from 'prop-types';

const Tab = ({tabName, size, weight, children, disable}) => {
  const [toggleState, setToggleState] = useState(0);
  const toggleTab = (index)=>{
    setToggleState(index);
  };

  const pointerEvent = disable ? 'pointer-events-none cursor-not-allowed':'';
    
  return (
    <div>
      <div className = "flex items-center">
        {tabName.map((name, key)=>(
          <div onClick={()=>toggleTab(key)} key={key} 
            className={`text-${size} cursor-pointer box-border md:box-content p-2 pb-2.5 leading-6 border-b-2 
                    ${toggleState === key ? 'border-secondary-500 text-secondary-500':`border-gray-300 border-opacity-0 text-gray-300 ${pointerEvent}`}
                    font-${weight}`}>
            {name}
          </div>
        ))}
      </div>
      {children[toggleState]}
    </div>
  );
};

export default Tab; 

Tab.propTypes = {
  className: PropTypes.string,
  tabName: PropTypes.array,
  children: PropTypes.array,
  disable: PropTypes.bool,
};

