import React from 'react';
import PropTypes from 'prop-types';


const CorporateId = ({ children}) => {
     
  return (
    <div className={'text-base font-normal text-primaray-900'}>{children}</div>
  );
};
               
export default CorporateId;

CorporateId.propTypes = {
  children: PropTypes.string,
};