import React from 'react';
import PropTypes from 'prop-types';

const Toaster = ({ body, ...property }) => {
  return (
    <div className={`${property.className}`}>
      <img src='https://image.shutterstock.com/image-vector/no-sign-icon-vector-illustration-260nw-1545904457.jpg'/>
      {body}
    </div>
  );
};
export default Toaster;

Toaster.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
};
