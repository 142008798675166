import React from 'react';
import PropTypes from 'prop-types';

const Breadcrumbs = (props) => {
  return (
    <nav className="mb-6 text-trueGray-700">
      <ol className="flex py-4 text-lg rounded list-reset bg-grey-light text-grey">
        { props.breadcrumbs.map((breadcrumb,i)=>(
          <li key={i}>
            <a href={`${breadcrumb.active ? '#' : breadcrumb.url}`} className={`${breadcrumb.active ? 'cursor-auto' : 'font-bold'}`}>{breadcrumb.title}</a>
            { !breadcrumb.active && <span className="mx-2">/</span> }
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
};