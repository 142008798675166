import React from 'react';
import PropTypes from 'prop-types';

const EmptyLayout = ({ children }) => {
  return <div className="page-content">{children}</div>;
};

EmptyLayout.propTypes = {
  children: PropTypes.node,
};

export default EmptyLayout;
