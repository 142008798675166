import React from 'react';
import PropTypes from 'prop-types';

const Email = ({email}) => {
  return (
    <div className={'text-sm font-normal pr-3 text-gray-600'}>
      {email}
    </div>
  );
};

export default Email;

Email.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
};