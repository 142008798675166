import { Image } from 'atoms'
import PrimaryGreenButton from 'atoms/PrimaryGreenButton'
import SecondaryGreenBUtton from 'atoms/SecondaryGreenBUtton'
import React from 'react'

const ContactUsModal = ({handelClose,handelClickMail,handelClickCall}) => {
  return (
    <div className='bg-black bg-opacity-50 fixed inset-0 z-50 w-full h-full  flex justify-center items-center px-1'>
        <div className='sm:w-[390px] w-[330px] bg-white rounded-[16px] h-auto flex flex-col justify-start items-start p-4'>
            <div className='w-full flex justify-between items-center '>
            <p className='text-[#2E2E2E] text-[20px] font-bold'>Contact us</p>
            <svg
            className="h-6 w-6 cursor-pointer p-1 hover:bg-gray-300 rounded-full"
            fill="currentColor"
            viewBox="0 0 20 20"

            onClick={handelClose}
            >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"></path>
          </svg>
            </div>
            <div className='w-full flex gap-2 justify-between items-center mt-5 border border-[#EEEEEE] rounded-[8px] p-4 h-[72px]'  onClick={handelClickCall}>
  {/* Call Us Section */}
  <a href="tel:+919513370337" className='w-full flex gap-2 justify-between items-center' >
    <div className='flex gap-2 justify-center items-center'>
      <div className='bg-[#029769] w-[48px] h-[48px] rounded-full flex justify-center items-center'>
        <Image src="/images/NewCall.svg" className="w-[24px] h-[24px]" />
      </div>
      <div className='flex flex-col items-start justify-start'>
        <h2 className='text-[#2E2E2E] text-[14px] font-semibold'>Call Us</h2>
        <p className='text-[#6E6E6E] text-[12px] fonr-medium'>+919513370337</p>
      </div>
    </div>
    <Image src="/images/RightA.svg" className="w-[24px] h-[24px]" />
  </a>
</div>
           
<div className='w-full flex gap-2 justify-between items-center mt-5 border border-[#EEEEEE] rounded-[8px] p-4 h-[72px]' onClick={handelClickMail}>
  {/* Mail Us Section */}
  <a href="mailto:support@monkeybox.in" className='w-full flex gap-2 justify-between items-center'>
    <div className='flex gap-2 justify-center items-center'>
      <div className='bg-[#029769] w-[48px] h-[48px] rounded-full flex justify-center items-center'>
        <Image src="/images/NewEmail.svg" className="w-[24px] h-[24px]" />
      </div>
      <div className='flex flex-col items-start justify-start'>
        <h2 className='text-[#2E2E2E] text-[14px] font-semibold'>Mail Us</h2>
        <p className='text-[#6E6E6E] text-[12px] fonr-medium'>support@monkeybox.in</p>
      </div>
    </div>
    <Image src="/images/RightA.svg" className="w-[24px] h-[24px]" />
  </a>
</div>
         
        </div>
    </div>
  )
}

export default ContactUsModal