import PrimaryRedBUtton from 'atoms/PrimaryRedBUtton'
import SecondaryRedBUtton from 'atoms/SecondaryRedBUtton'
import React from 'react'

const LogoutConfirm = ({handelClose,onClick}) => {
  return (
    <div className='bg-black bg-opacity-50 fixed inset-0 z-50 w-full h-full  flex justify-center items-center px-1'>
        <div className='sm:w-[390px] w-[330px] bg-white rounded-[16px] h-auto flex flex-col justify-start items-start p-4'>
            <div className='w-full flex justify-between items-center '>
            <p className='text-[#2E2E2E] text-[20px] font-bold'></p>
            <svg
            className="h-6 w-6 cursor-pointer p-1 hover:bg-gray-300 rounded-full"
            fill="currentColor"
            viewBox="0 0 20 20"

            onClick={handelClose}
            >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"></path>
          </svg>
            </div>
            <p className='text-[#2E2E2E] text-[20px] font-bold'>Logout</p>
        <h2 className='text-[#4E4E4E] text-[14px] font-thin'>Are you  sure?</h2>

          <div className='mt-4 flex gap-4 justify-center w-full'>
            <SecondaryRedBUtton cta='No' onClick={handelClose}/>

            <PrimaryRedBUtton cta='Yes' onClick={onClick}/>
          </div>
      
         
        </div>
    </div>
  )
}

export default LogoutConfirm