import React from 'react';
import PropTypes from 'prop-types';

const Avatar = ({ ...property }) => {
  return (
    <div className={` ${property.className}flex justify-center items-center w-8 h-8 text-center rounded-${property.rounded} overflow-hidden text-white text-xl h-${property.height} w-${property.width}`}>
      <img onClick={property.onClick} 
        src={property.src ? property.src : '/images/user.png'} width={property.width} height={property.height} 
        className="w-full h-full object-cover rounded-2xl cursor-pointer"  
      />
    </div>
  );
};
export default Avatar;

Avatar.propTypes = {
  className: PropTypes.string,
};
