import React from "react";
import PropTypes from "prop-types";

const Text = ({ children, style, ...property }) => {
  return (
    <div className={property.className} style={style}>
      {children}
    </div>
  );
};

export default Text;

Text.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};
