import React from 'react';
import PropTypes from 'prop-types';


const HeadTitle = ({className, children}) => {
  return (
    <div className={className}>{children}</div>
  );
};
               
export default HeadTitle;

HeadTitle.propTypes = {
  children: PropTypes.string,
  className:PropTypes.string
};       