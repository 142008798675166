import * as HttpService from "./http.service";
import {
  GET_PARENT,
  CREATE_ORDER_BTB_KIDS,
  GET_REFERRAL_HISTORY,
  GET_REFERRAL_CODE,
  GET_SETTINGS,
} from "./url.service";

export const getReferralHistory = (userId, token) => {
  return HttpService.getWithAuth(GET_REFERRAL_HISTORY(userId), token);
};

export const getReferralCode = (userId, token) => {
  return HttpService.getWithAuth(GET_REFERRAL_CODE(userId), token);
};

export const createOrder = (parentId, kidId, menuId, token) => {
  return HttpService.postWithAuth(
    CREATE_ORDER_BTB_KIDS(parentId, kidId),
    { menuId },
    token
  );
};

export const getSettings = (userId, token) => {
  return HttpService.getWithAuth(GET_SETTINGS(userId), token);
};
