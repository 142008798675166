import React from "react";
import propTypes from "prop-types";

function ImageIcon({ src, pointer = true, ...props }) {
  return (
    <div className={`${pointer ? "cursor-pointer" : ""} `}>
      <img
        className={`${props.className}`}
        src={src}
        alt="icon"
        onClick={props.handleClick}
        loading="lazy"
      />
    </div>
  );
}

export default ImageIcon;
ImageIcon.propTypes = {
  src: propTypes.string,
};
