import React from 'react';
import PropTypes from 'prop-types';
import Text from './Text';

const NewAvtarV3 = ({ ...property }) => {
  return (
    
    <div className="flex justify-center items-center mx-auto cursor-pointer">
    {property.src ? (
      <img
        src={property.src}
        className="flex justify-center w-[3rem] h-[3rem] rounded-full object-cover"
      />
    ) : (
      <div className='flex justify-center items-center flex-col gap-3'>
<div className="flex justify-center items-center w-[3rem] h-[3rem] rounded-full bg-[#029769]">
        <Text className="text-white text-[16px]">
          {property?.lebel?.charAt(0)}
        </Text>
      </div>
      
      </div>
     
    )}
  </div>
  );
};
export default NewAvtarV3;

NewAvtarV3.propTypes = {
  className: PropTypes.string,
};
