import React from "react";
import PropTypes from "prop-types";
import Text from "../atoms/Text";
import NewAvtarV3 from "atoms/NewAvtarV3";

function NavBarProfile({ src, lebel, onClick }) {
  return (
    <div onClick={onClick} className="w-22 h-10 flex items-center ">
      
      <NewAvtarV3 src={src} lebel={lebel}/>
    </div>
  );
}

export default NavBarProfile;

NavBarProfile.propTypes = {
  src: PropTypes.string,
  lebel: PropTypes.string,
  onClick: PropTypes.func,
};
