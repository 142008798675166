import { Button, NavLink } from "atoms";
import { useRouter } from "next/router";
import React from "react";
import moment from "moment";
import { useState } from "react";
const Footer = () => {
  const router = useRouter();
  const [selectedDate] = useState(moment().format("YYYY-MM-DD"));
  let kidId = router.query?.id;
  const { pathname } = router;
  const isMatchingPathForHome = pathname.includes("/menus");
  const isMatchingPathForOrder = pathname.includes("/Order-Details");
  const isMatchingPathForSubscriptions = pathname.includes("/subscriptions");
  const isMatchingPathForProfile = pathname.includes("/account-settings");

  const gotoorder = () => {
    router.push({
      pathname: `/child/${kidId}/Order-Details`,
      query: {
        isAutoOrderActive: false,
        date: selectedDate,
      },
    });
  };
  const planSetting = () => {
    router.push({
      pathname: `/child/${kidId}/account-settings`,
    });
  };
  const goToHomePage = () => {
    router.push({
      pathname: `/child/${kidId}/menus`,
    });
  };
  // planSetting={() => {
  //   router.push(`/child/${selectedKid.details.id}/account-settings`);
  // }}
  return (
    <div className="h-auto py-2 bg-white z-50 w-full fixed flex justify-center items-center bottom-0 md:hidden">
      <div className="  gap-10 flex  justify-center items-start mx-4">
        <div
          className="flex flex-col gap-1 justify-center items-center  cursor-pointer"
          onClick={goToHomePage}
        >
          {isMatchingPathForHome ? (
            <div className="bg-[#F93360] h-[2px] rounded-full w-[20px] mb-2" />
          ) : (
            <div className="bg-[#fff] h-[2px] rounded-full w-[20px] mb-2" />
          )}
          <img src="/images/HomeIcon.svg" className="w-[20px] h-[20px]" />
          <div className="flex flex-col justify-center items-center">
            <p className="text-xs font-semibold">Home</p>
          </div>
        </div>

        <button
          className="flex flex-col gap-1 justify-center items-center  cursor-pointer"
          onClick={gotoorder}
        >
          {isMatchingPathForOrder ? (
            <div className="bg-[#F93360] h-[2px] rounded-full w-[20px] mb-2" />
          ) : (
            <div className="bg-[#fff] h-[2px] rounded-full w-[20px] mb-2" />
          )}
          <img src="/images/OrdersIcon.svg" className="w-[20px] h-[20px]" />
          <div className="flex flex-col justify-center items-center">
            <p className="text-xs font-semibold">Orders</p>
          </div>
        </button>
        <button className="flex flex-col gap-1 justify-center items-center  cursor-pointer">
          {isMatchingPathForSubscriptions ? (
            <div className="bg-[#F93360] h-[2px] rounded-full w-[20px] mb-2" />
          ) : (
            <div className="bg-[#fff] h-[2px] rounded-full w-[20px] mb-2" />
          )}
          <img
            src="/images/SubscriptionIcon.svg"
            className="w-[20px] h-[20px]"
          />
          <div className="flex flex-col justify-center items-center">
            <p className="text-xs font-semibold">Subscriptions</p>
          </div>
        </button>
        <button
          className="flex flex-col gap-1 justify-center items-center  cursor-pointerr"
          onClick={planSetting}
        >
          {isMatchingPathForProfile ? (
            <div className="bg-[#F93360] h-[2px] rounded-full w-[20px] mb-2" />
          ) : (
            <div className="bg-[#fff] h-[2px] rounded-full w-[20px] mb-2" />
          )}
          <img src="/images/ProfileIcon.svg" className="w-[20px] h-[20px]" />
          <div className="flex flex-col justify-center items-center">
            <p className="text-xs font-semibold">Profile</p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Footer;
