import React, { useEffect, useState } from 'react';
import Text from '../atoms/Text';
import Avatar from '../atoms/Avatar';
import { useRouter } from 'next/router';
import { getAuth, removeAuth } from '../services/identity.service';
import { getKidDetails } from '../services/kids.service';
import Button from '../atoms/Button';
import Spinner from 'atoms/Spinner';
import ImageIcon from 'atoms/ImageIcon';
import ContactUsModal from '@/components/NewAddChild/ContactUsModal';
import SmallLoader from 'atoms/SmallLoader';

function NewProfileDropDownV3({
  handleKidSelect,
  setDropdownStatus,
  viewProfile,
  accountSetting,
  kidList,
  id,
  handleClickOutside,
  SelectekidId,

  handelProfleClose,
  openhelpModal,
  openAbout,
  HandelReferAndEarn,
  onLogoutClick,
  AddaChild,
  selectedKidId
}) {
  const router = useRouter();
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [EditLoading, setEditLoading] = useState(false);
  const [editLoadingKidId, setEditLoadingKidId] = useState(null);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  useEffect(async () => {
    setLoading(true);
    const auth = getAuth();
    const response = await getKidDetails(auth.id, id, auth.token);
    setAmount(response?.data?.entity?.Wallet?.amount);
    setLoading(false);
  }, [amount]);

  const truncateText = (description, maxLength = 9) => {
    if (description.length > maxLength) {
      return `${description.substring(0, maxLength)}...`;
    }
    return description;
  };

  console.log('kidListkidList', kidList);
  return (
    <>
      <div
        className={`fixed z-50 top-20 right-1 mt-0 md:mt-2  w-full md:w-1/2 bg-white rounded-2  flex-col flex shadow-sm h-screen  `}
      >
        <p className=" text-NewPrimaryColor font-semibold text-[16px] px-5 mt-5">
          Kid's
        </p>

        <div className="flex gap-3 justify-start items-start w-full p-5 mt-[-10px]">
          <div
            className="flex flex-col gap-1  justify-center items-center cursor-pointer "
            onClick={AddaChild}
          >
            <div className="flex   justify-center items-center w-[4rem] h-[4rem] border border-dashed border-[#029769] bg-[#F3FAF7] rounded-full">
              <img src="/images/Green-Plus.svg" className="" />
            </div>
            <button className="text-NewPrimaryColor font-semibold text-[16px]">
              Add
            </button>
          </div>

          <div className="flex justify-start items-start gap-3 w-full overflow-x-auto">
  {[
    // Move the selected kid to the front of the list (only if found)
    ...kidList?.filter((kid) => kid.id === selectedKidId), 
    ...kidList?.filter((kid) => kid.id !== selectedKidId), // Remaining kids
  ].filter(Boolean) // Ensure there are no undefined values
    .map((kid) => {
      console.log("kidv2", kid);
      return (
        <div
          key={kid.id} // Add a unique key for each kid
          className="flex flex-col justify-center items-center gap-1 cursor-pointer"
          onClick={() => {
            handleKidSelect(kid); // Trigger kid selection
            setDropdownStatus(true); // Open dropdown
          }}
          aria-hidden="true"
        >
          {kid.photo ? (
            <div className="max-w-[4rem] min-w-[4rem] min-h-[4rem] max-h-[4rem] rounded-full relative">
              {/* Edit Icon */}
              <div
                className="absolute top-0 right-0"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditLoadingKidId(kid.id); // Set loading for the clicked kid
                  router
                    .push(`/children/${kid.id}/edit?isSchoolEdit=true`)
                    .finally(() => {
                      setEditLoadingKidId(null); // Reset loading state after navigation
                    });
                }}
              >
                <div className="w-6 h-6 rounded-full bg-[#dbdbdb] flex justify-center items-center p-0.5">
                  <div className="w-full h-full rounded-full bg-[#F3FAF7] border-1 border-[#DDDDD] flex justify-center items-center">
                    {editLoadingKidId === kid.id ? (
                      <SmallLoader /> // Show loader only for the clicked kid
                    ) : (
                      <img
                        src="/images/editI.svg"
                        className="w-[8px] h-[8px]"
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* Kid Photo */}
              <img
                src={kid?.photo}
                className="flex justify-center max-w-[4rem] min-w-[4rem] min-h-[4rem] max-h-[4rem] rounded-full object-cover"
                alt="Profile-Image"
              />
            </div>
          ) : (
            <div className="flex justify-center items-center flex-col gap-3">
              {/* Default Avatar */}
              <div className="flex justify-center items-center max-w-[4rem] min-w-[4rem] min-h-[4rem] max-h-[4rem] rounded-full bg-[#029769] relative">
                <div
                  className="absolute top-0 right-0"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditLoadingKidId(kid.id); // Set loading for the clicked kid
                    router
                      .push(`/children/${kid.id}/edit?isSchoolEdit=true`)
                      .finally(() => {
                        setEditLoadingKidId(null); // Reset loading state after navigation
                      });
                  }}
                >
                  <div className="w-6 h-6 rounded-full bg-[#dbdbdb] flex justify-center items-center p-0.5">
                    <div className="w-full h-full rounded-full bg-[#F3FAF7] border-1 border-[#DDDDD] flex justify-center items-center">
                      {editLoadingKidId === kid.id ? (
                        <SmallLoader /> // Show loader only for the clicked kid
                      ) : (
                        <img
                          src="/images/editI.svg"
                          className="w-[8px] h-[8px]"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* First Initial of Kid's Name */}
                <Text className="text-white text-[16px]">
                  {kid.fullName.charAt(0)}
                </Text>
              </div>
            </div>
          )}
          {/* Kid's Name */}
          <Text
            className={`font-semibold text-[16px] ${
              kid.id === selectedKidId ? "text-green-500" : "text-NewPrimaryColor"
            }`}
          >
            {truncateText(kid.fullName)}{' '}
            {/* Truncate the name if it's too long */}
          </Text>
        </div>
      );
    })}
</div>

        </div>

        <div className="p-5 flex flex-col gap-3  justify-start items-start  w-full h-[600px] sm:h-[300px] overflow-y-scroll pb-12 relative">
          <div className="flex flex-col gap-2 justify-start w-full">
            <div
              className="flex  gap-3  justify-between items-center w-full cursor-pointer"
              onClick={accountSetting}
            >
              <div className="flex jusify-center items-center gap-4">
                <div className="bg-[#F8F8F8] rounded-full w-[55px] h-[55px] flex justify-center items-center ">
                  <ImageIcon src="/images/Account.svg" className="" />
                </div>
                <div className="flex flex-col justify-start">
                  <p className=" text-NewPrimaryColor font-semibold text-[24px] ">
                    Account
                  </p>
                  <p className=" text-[#4E4E4E] font-thin text-[18px] ">
                    Edit name, number
                  </p>
                </div>
              </div>
              <ImageIcon
                src="/images/RightA.svg"
                className="w-[24px] h-[24px]  "
              />
            </div>
            <div className="bg-[#DDDDDD] h-[1px] w-full" />
          </div>
          <div className="flex flex-col gap-2 justify-start w-full">
            <div
              className="flex  gap-3  justify-between items-center w-full cursor-pointer"
              onClick={HandelReferAndEarn}
            >
              <div className="flex jusify-center items-center gap-4">
                <div className="bg-[#F8F8F8] rounded-full w-[55px] h-[55px] flex justify-center items-center ">
                  <ImageIcon src="/images/Refer.svg" className="" />
                </div>
                <div className="flex flex-col justify-start">
                  <p className=" text-NewPrimaryColor font-semibold text-[24px] ">
                    Refer & Earn
                  </p>
                  <p className=" text-[#4E4E4E] font-thin text-[18px] ">
                    Share your code
                  </p>
                </div>
              </div>
              <ImageIcon
                src="/images/RightA.svg"
                className="w-[24px] h-[24px]  "
              />
            </div>
            <div className="bg-[#DDDDDD] h-[1px] w-full" />
          </div>
          <div className="flex flex-col gap-2 justify-start w-full">
            <div
              className="flex  gap-3  justify-between items-center w-full cursor-pointer"
              onClick={openhelpModal}
            >
              <div className="flex jusify-center items-center gap-4">
                <div className="bg-[#F8F8F8] rounded-full w-[55px] h-[55px] flex justify-center items-center ">
                  <ImageIcon src="/images/Help.svg" className="" />
                </div>
                <div className="flex flex-col justify-start">
                  <p className=" text-NewPrimaryColor font-semibold text-[24px] ">
                    Help
                  </p>
                  <p className=" text-[#4E4E4E] font-thin text-[18px] ">
                    Help center
                  </p>
                </div>
              </div>
              <ImageIcon
                src="/images/RightA.svg"
                className="w-[24px] h-[24px]  "
              />
            </div>
            <div className="bg-[#DDDDDD] h-[1px] w-full" />
          </div>
          <div className="flex flex-col gap-2 justify-start w-full">
            <div
              className="flex  gap-3  justify-between items-center w-full cursor-pointer"
              onClick={openAbout}
            >
              <div className="flex jusify-center items-center gap-4">
                <div className="bg-[#F8F8F8] rounded-full w-[55px] h-[55px] flex justify-center items-center ">
                  <ImageIcon src="/images/Account.svg" className="" />
                </div>
                <div className="flex flex-col justify-start">
                  <p className=" text-NewPrimaryColor font-semibold text-[24px] ">
                    About
                  </p>
                  <p className=" text-[#4E4E4E] font-thin text-[18px] ">
                    Privacy, Terms and conditions
                  </p>
                </div>
              </div>
              <ImageIcon
                src="/images/RightA.svg"
                className="w-[24px] h-[24px]  "
              />
            </div>
            <div className="bg-[#DDDDDD] h-[1px] w-full" />
          </div>
          <div className="sticky  left-1/2 transform -translate-x-1/2 bottom-[-100px] sm:bottom-[-50px] bg-white p-2 ">
            <div
              className="flex gap-3 justify-center items-center cursor-pointer  w-full bg-white"
              onClick={onLogoutClick}
            >
              <div className="bg-[#FDF3F4] rounded-full w-[55px] h-[55px] flex justify-center items-center ">
                <ImageIcon src="/images/Logout.svg" className="" />
              </div>
              <p className=" text-NewPrimaryColor font-semibold text-[24px] ">
                Logout
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewProfileDropDownV3;
