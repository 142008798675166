import React from "react";
import PropTypes from "prop-types";

const NewAvtar = ({ ...property }) => {
  return (
    <div
      className={` ${property.className}flex justify-center items-center w-8 h-8 text-center rounded-${property.rounded} overflow-hidden text-white text-xl h-${property.height} w-${property.width}`}>
      <img
        onClick={property.onClick}
        src={property.src}
        width={property.width}
        height={property.height}
        className="md:w-full md:h-full w-4 h-4  object-cover rounded-2xl cursor-pointer"
      />
    </div>
  );
};
export default NewAvtar;

NewAvtar.propTypes = {
  className: PropTypes.string,
};
