import { createSlice } from "@reduxjs/toolkit";

const initialState = { details: { id: "", fullName: "", photo: null } };

const switchKidSlice = createSlice({
  name: "selectedKid",
  initialState,
  reducers: {
    updateSelectedKid(state, action) {
      state.details = action.payload;
    },
  },
});

export const switchKidAction = switchKidSlice.actions; // creates unique actions for different reducers
export default switchKidSlice.reducer;
