
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';

const OTPInput = ({ otpValue, length, isNumberInput, seperator, handleChange, errored, shouldAutoFocus, errorMessage }) => {
  return (
    <div className="w-full">
      <OtpInput
        value={otpValue}
        onChange={(value) => handleChange(value)}
        numInputs={length}
        separator={seperator}
        containerStyle={{
          fontSize: '16px',
          color: '#14142B',
        }}
        hasErrored={errored}
        inputStyle={{
          width: '41px',
          height: '48px',
          border: '1px solid #D1D1DB',
          boxSizing: 'border-box',
          marginRight: 16,
          borderRadius: '8px',
          outline:'none',
          
        }}
        isInputNum={isNumberInput}
        focusStyle={{
          outline: 'none',
          
        }}
        errorStyle={{
          border: '1px solid red',
        }}
        shouldAutoFocus={shouldAutoFocus}
      />
      {errored &&
				<div className='text-error text-xs font-semibold my-1 ml-4 md:text-center lg:text-left color-red-300'>{errorMessage}</div>
      }
    </div>
  );
};
export default OTPInput;

OTPInput.propTypes = {
  otpValue: PropTypes.string,
  length: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  seperator: PropTypes.elementType,
  errored: PropTypes.bool,
  isNumberInput: PropTypes.bool,
  shouldAutoFocus: PropTypes.bool,
};
OTPInput.defaultProps = {
  errored: false,
  isNumberInput: true,
  shouldAutoFocus: true,
};
