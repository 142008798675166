import React from 'react';
import PropTypes from 'prop-types';
import Heading from './Heading';

const NavLink = ({ text, size, weight, href, color }) => {
  return (
    <Heading className={`text-${size} font-${weight} text-${color}`} type="h4">
      {text}
    </Heading>
  );
};

export default NavLink;

NavLink.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};
