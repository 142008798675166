import React, { useEffect, useState } from "react";
import Text from "../atoms/Text";
import Avatar from "../atoms/Avatar";
import { useRouter } from "next/router";
import { getAuth, removeAuth } from "../services/identity.service";
import { getKidDetails } from "../services/kids.service";
import Button from "../atoms/Button";
function ProfileDropDownB2B({ handleKidSelect, kid }) {
  const router = useRouter();
  return (
    <div className="fixed z-50 top-20 right-1 mt-1 w-48 bg-white rounded-2  flex-col flex shadow-sm ">
      <Button
        onClick={() => {
          router.push({
            pathname: "/canteen/children",
            query: { isB2B: true },
          });
        }}
        className=" w-full flex justify-start ml-5 p-3 font-medium text-sm text-black"
      >
        Switch Account
      </Button>
      <Button
        onClick={() => {
          removeAuth();
          router.push("/login");
        }}
        className=" w-full flex justify-start ml-5 p-3 font-medium text-sm text-black"
      >
        Log out
      </Button>
    </div>
  );
}

export default ProfileDropDownB2B;
