const TextField = ({ register,  type,error,id }) => {
  return (
    <div className="relative flex flex-col w-full">
    
      <input
        {...register(id)}
        id={id}
        type={type}
      />
      {error && (
        <div className="text-semantic-error  text-xs font-normal ">
          {error.message}
        </div>
      )}
    </div>
  );
};

export default TextField;