import React from "react";
import TextLink from "../atoms/TextLink";
import ImageIcon from "../atoms/ImageIcon";
import PropTypes from "prop-types";

function OldNavLink({ src, label, href }) {
  return (
    <div className="w-22 h-10 flex items-center ">
      <ImageIcon src={src}></ImageIcon>
      <TextLink
        href={href}
        className="text-black  text-base ml-1 font-semibold text-md">
        {label}
      </TextLink>
    </div>
  );
}

export default OldNavLink;
OldNavLink.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};
