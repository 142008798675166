import React from 'react'

const SecondaryRedBUtton = ({style="w-full",cta="",onClick}) => {
  return (
    <button className={`bg-[#FDF3F4] profilebgshadow h-[54px] rounded-[8px] border border-[#F93360] flex justify-center items-center text-center text-[14px] text-[#F93360] font-semibold shadow-md hover:bg-[#fff] hover:text-[#000] ease-linear duration-500 ${style}`} onClick={onClick}>
    
    {cta}
   </button>
  )
}

export default SecondaryRedBUtton