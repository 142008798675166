import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentOrder: {},
  editOrder: [],
  editedOrder: [],
  cancelledOrders: [],
  newMenus: [],
  cancelledMenus: [],
  newSubscriptionMenus: {},
  newSubscriptionCancelledOrders: {},
};

const orderSlice = createSlice({
  name: "editOrder",
  initialState,
  reducers: {
    updateCurrentOrderOrder(state, action) {
      state.currentOrder = action.payload;
      state.editOrder = [];
      state.cancelledOrders = [];
      state.newMenus = [];
      state.cancelledMenus = [];
      state.newSubscriptionMenus = {};
      state.newSubscriptionCancelledOrders = {};
    },
    updateEditOrder(state, action) {
      state.editOrder = action.payload.ids;
      state.newMenus = action.payload.menus;
    },
    updateCancelledOrder(state, action) {
      state.cancelledOrders = action.payload.ids;
      state.cancelledMenus = action.payload.menus;
    },
    updateSubcsriptionNewOrder(state, action) {
      state.newSubscriptionMenus = action.payload.menus;
      state.newSubscriptionCancelledOrders = action.payload.ids;
    },
    updateEditedOrder(state, action) {
      state.editedOrder = action.payload.data;
    },
  },
});

export const orderAction = orderSlice.actions; // creates unique actions for different reducers
export default orderSlice.reducer;
