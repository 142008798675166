import React from 'react';
import propTypes from 'prop-types';

function Image({ src, ...props }) {
  return (
    <div className={props.className}>
      <img className=" h-full w-full cursor-pointer" src={src} alt="icon" onClick={props.handleClick} />
    </div>
  );
}

export default Image;
Image.propTypes = {
  src: propTypes.string,
};
