import React from 'react';
import PropTypes from 'prop-types';

const InputLabel = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export default InputLabel;

InputLabel.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
};
