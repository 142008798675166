import Select from 'react-select';
import PropTypes from 'prop-types';

const   options= [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FM', label: 'Federated States Of Micronesia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
];

const Dropdown = ({
  
  name,
  isDisabled,
  isLoading,
  isClearable,
  isSearchable,
  isMulti,
  onChange,
  defaultValueIndex,
  ...properties
}) => {
  return (
    <div className="w-60 md:w-98 ">
      <Select
      
        isMulti={isMulti}
        classNamePrefix="select"
        placeholder={`${properties.placeholder ? properties.placeholder : 'Select...'
        }`}
        defaultValue={options[defaultValueIndex]}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        name={name}
        closeMenuOnSelect={!isMulti}
        options={options}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#3655F2',
          },
        })}
        styles={{
          dropdownIndicator: (provided, state) => ({
            ...provided,
            transition: 'all .2s ease',
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
          }),
        }}
        onChange={onChange}
      />
    </div>
  );
};
export default Dropdown;

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValueIndex: PropTypes.number,
};

Dropdown.defaultProps = {
  isDisabled: false,
  isLoading: false,
  isClearable: false,
  isSearchable: false,
  isMulti: false,
};
