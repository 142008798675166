import PropTypes from "prop-types";
const Button = ({ children, ...property }) => {
  return (
    <button
      id={property.id}
      onClick={property.onClick}
      onKeyPress={property.onKeyPress}
      onKeyDown={property.onKeyDown}
      disabled={property.disabled}
      className={
        property.disabled ? property.disabledClassName : property.className
      }
      type={property.type}
    >
      {children}
    </button>
  );
};
export default Button;

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
