import React, { useState, useEffect } from "react";
import ImageIcon from "../atoms/ImageIcon";
import Text from "../atoms/Text";
import PrimaryLoader from "atoms/PrimaryLoader";
import { getAuth } from "services/identity.service";
import * as R from "ramda";
import { getParentsFeedback } from "../services/parents.service";

function NewLoaderWithText() {
  const [saveFeedbacks, setSaveFeedbacks] = useState();
  const [randomRes, setRandomRes] = useState(null); // Initialize with null

  const auth = getAuth();

  useEffect(() => {
    async function fetchData() {
      const res = await getParentsFeedback(auth.id, auth.token);
      const feedbacks = R.pathOr(null, ["data", "entity"], res);

      setSaveFeedbacks(feedbacks);

      if (feedbacks !== null && feedbacks.length > 0) {
        const randomIndex = Math.floor(Math.random() * feedbacks.length);
        const randomResponse = feedbacks[randomIndex];
        setRandomRes(randomResponse);
      }
    }

    fetchData();
  }, []);

  if (randomRes === null) {
    return (
      <div className="w-full sm:w-[50%] h-1/2 md:w-[50%] flex justify-center items-center mx-auto  lg:w-[30%] md:p-10 inset-0 sm:p-10 p-4 py-8 mx-2 sm:mx-3 md:mx-4 bg-white rounded-xl shadow-2xl">
      {/* <Text className="text-[25px] text-center font-normal">
        Loading...
      </Text> */}
      <PrimaryLoader />
      {/* <Text className="text-sm font-extralight my-5 text-center">
        Wait while we are processing...
      </Text> */}
    </div>
    );
  }

  return (
    <div className="w-full sm:w-[50%] md:w-[50%] lg:w-[30%] md:p-10 inset-0 sm:p-10 p-4 py-8 mx-2 sm:mx-3 md:mx-4 bg-white rounded-xl shadow-2xl">
      <Text className="text-[25px] text-center font-normal">
        {randomRes.text}
      </Text>
      <div className="flex justify-center items-center mt-12">
        <ImageIcon
          src={
            randomRes.spouse === "Dad"
              ? "/images/male_dummy_image.png"
              : "/images/female_dummy_image.png"
          }
          className="w-12 h-12 rounded-full bg-cover max-w-none"
        />
      </div>
      <Text className="text-md font-normal mt-5 text-center">
        {randomRes.parentName ? randomRes.parentName : "Priya"}
      </Text>
      <Text className="text-md font-extralight mb-5 text-center">
        {`${randomRes.spouse ? randomRes.spouse : "Mom"} of ${
          randomRes.kidName ? randomRes.kidName : "Aarav"
        }`}
      </Text>
      <PrimaryLoader />
      <Text className="text-sm font-extralight my-5 text-center">
        Wait while we are processing...
      </Text>
    </div>
  );
}

export default NewLoaderWithText;
