import React from "react";
import propTypes from "prop-types";
import NavLogo from "../atoms/ImageIcon";
import NavLink from "../molecules/NavLink";
import NavBarProfile from "../molecules/NavBarProfile";
import { Button, Text } from "atoms";
import { useRouter } from "next/router";
import ImageIcon from "../atoms/ImageIcon";
import { checisReferralActive, getAuth } from "services/identity.service";
import { getSubscriptionDetails } from "services/plans.service";
import { useEffect, useState } from "react";
import NewNavbarProfile from "molecules/NewNavbarProfile";
import Avatar from "../atoms/Avatar";
import NewAvtar from "atoms/NewAvtar";
import Link from "next/link";
import { Webengage } from "constants/webengage";
const R = require("ramda");
function NewHeader({
  kidName,
  isB2B,
  photo,
  orderHref,
  reportHref,
  setDropdownStatus,
  dropdownStatus,
  onLogoClick,
  goToprofilePage,
  settings,
  planName,
  goToHomePage,
  kid,
  planSetting,
  viewPlans,
  showChildListAndprofile,
  showChildList,
  otherKid,
  kidId,
  kidList,
  viewOrdeDetails,
  goToAccountDetailspage,
  handelProfleClick,

  isMatchingPathForHome,
  isMatchingPathForOrder,
  isMatchingPathForSubscriptions,
  isMatchingPathForProfile,
}) {
  const router = useRouter();
  const auth = getAuth();
  const [value, setvaluePlan] = useState([]);

  let planChanged = router.query?.planChanged || false;

  const getKidSubscription = async () => {
    const res = await getSubscriptionDetails(
      auth.id,
      router.query.id || kidId,
      auth.token
    );

    const subRes = await R.pathOr(null, ["data", "entity"], res);

    setvaluePlan(subRes);
  };

  useEffect(async () => {
    await getKidSubscription();
  }, [planChanged]);
  const [truncatedName, setTruncatedName] = useState("");

  useEffect(() => {
    const originalName = value?.activeSubscription?.Plan?.name;
    if (originalName && originalName.length > 12) {
      const truncated = originalName.slice(0, 12) + "...";
      setTruncatedName(truncated);
    } else {
      setTruncatedName(originalName);
    }
  }, [value]);

  console.log("valuevaluevalue", kidName);
  const isRefrralActive = checisReferralActive(settings);

  useEffect(() => {
    console.log(kidId, kid, "kid id =========");
  }, [kidId]);

  const goToAddChild = () => {
    router.push({
      pathname: "/children/add-child",
    });
  };
 const handeliClickDropDown = ()=>{
  window.webengage.track(Webengage.VIEW_SIDEBAR, {
   kidId: router.query.id || kidId,
parentId:auth.id,

  });
  setDropdownStatus(!dropdownStatus)
 }
  return (
    <>
      <div className="flex fixed top-0 left-0 z-[1000] justify-between shadow-sm items-center h-auto py-4 w-full bg-white">
        <div className="ml-3 md:ml-7">
          <div className="flex items-center">
            <NavLogo
              src="/images/navLogo.svg"
              handleClick={goToHomePage}
              className=""
            />
            {
              !!kid &&
              <div className=" md:ml-[3rem] gap-4 md:flex hidden justify-center items-start mx-4">
                <div
                  className="flex gap-2 justify-start items-start  cursor-pointer"
                  onClick={goToHomePage}
                >
                  <img src="/images/HomeIcon.svg" className="w-[20px] h-[20px]" />
                  <div className="flex flex-col justify-center items-center">
                    <p className="text-md font-semibold">Home</p>
                    {isMatchingPathForHome && (
                      <div className="bg-[#F93360] h-[2px] rounded-full w-[40px]" />
                    )}
                  </div>
                </div>

                <button
                  className="flex gap-2 justify-start items-start  cursor-pointer"
                  onClick={viewOrdeDetails}
                >
                  <img
                    src="/images/OrdersIcon.svg"
                    className="w-[20px] h-[20px]"
                  />
                  <div className="flex flex-col justify-center items-center">
                    <p className="text-md font-semibold">Orders</p>
                    {isMatchingPathForOrder && (
                      <div className="bg-[#F93360] h-[2px] rounded-full w-[40px]" />
                    )}
                  </div>
                </button>
                <button
                  className="flex gap-2 justify-start items-start  cursor-pointer"
                  onClick={viewPlans}
                >
                  <img
                    src="/images/SubscriptionIcon.svg"
                    className="w-[20px] h-[20px]"
                  />
                  <div className="flex flex-col justify-center items-center">
                    <p className="text-md font-semibold">Subscriptions</p>
                    {isMatchingPathForSubscriptions && (
                      <div className="bg-[#F93360] h-[2px] rounded-full w-[90px]" />
                    )}
                  </div>
                </button>
                <button
                  className="flex gap-2 justify-start items-start  cursor-pointer"
                  onClick={planSetting}
                >
                  <img
                    src="/images/ProfileIcon.svg"
                    className="w-[20px] h-[20px]"
                  />
                  <div className="flex flex-col justify-center items-center">
                    <p className="text-md font-semibold">Profile</p>
                    {isMatchingPathForProfile && (
                      <div className="bg-[#F93360] h-[2px] rounded-full w-[40px]" />
                    )}
                  </div>
                </button>
              </div>
            }
          </div>
        </div>{" "}
        <div className="flex ml-1">
          {isB2B ? null : auth?.roles.includes("Parent Offline B2B") ||
            auth?.roles.includes("Parent B2B2C") ? (
            <div className="mr-4 md:mr-32">
              <NavLink
                src="/images/Bagblack.svg"
                label="Report"
                href={reportHref}
              />
            </div>
          ) : (
            <div className="mr-4">
              {/* <NavLink
                src="/images/Bagblack.svg"
                label="Orders"
                href={orderHref}
              /> */}
            </div>
          )}

          {!!kid ? (
            <div
              className="mr-5"
              onClick={handeliClickDropDown}
            >
              <NavBarProfile src={photo} lebel={kidName} />
            </div>
          ) : (
            <div
              onClick={handeliClickDropDown}
              className="mr-5 flex   justify-center items-center w-[4rem] h-[4rem] border border-dashed border-[#029769] bg-[#F3FAF7] rounded-full"
            >
             <img src="/images/Green-Plus.svg" className="" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default NewHeader;
NewHeader.propTypes = {
  profilesrc: propTypes.string,
  profilename: propTypes.string,
  onclick: propTypes.func,
};
