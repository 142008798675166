import React from 'react'

const PrimaryRedBUtton = ({style="w-full",cta="",onClick}) => {
  return (
    <button className={`bg-[#F93360] h-[54px] rounded-[8px] border border-[#F93360] flex justify-center items-center text-center text-[14px] text-[#fff] font-semibold profilebgshadow hover:bg-[#fff] hover:text-[#000] ease-linear duration-500 ${style}`} onClick={onClick}>
    
    {cta}
   </button>
  )
}

export default PrimaryRedBUtton