import React from 'react';
import PropTypes from 'prop-types';


const Tag = ({ children}) => {
     
  return (
    <div className={'border-2 border-blue-500 rounded-full font-light px-4 py-2 transition duration-300 ease-in-out hover:bg-blue-500 hover:text-white mr-6 rounded-2xl border inline-block py-1 px-2 border-gray-400 ml-2 text-sm'}>{children}</div>
  );
};
               
export default Tag;

Tag.propTypes = {
  children: PropTypes.string,
};