import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({image}) => {
  return (
    <img src={image}  width={100} height={31.1}/>
  );
};

export default Logo;

Logo.propTypes={
  image: PropTypes.string,
};