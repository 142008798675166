export const isProtected = (route) => {
  let unprotectedRoutes = [
    "/",
    "/login",
    "/terms-and-conditions",
    "/faq/plan/ad-hoc-plan",
    "/faq/plan/premium-lunch-5-day-plan",
    "/faq/plan/premium-lunch-breakfast-5-day-plan",
    "/static/monkeybox-privacy-policy",
  ];

  return !unprotectedRoutes.includes(route);
};
