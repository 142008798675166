import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";

const initialState = { 
  isLoading: false,
  data: {
    feedbacks: [],
    orderConfig: null,
    settingDetails: []
  }
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    settingsDataLoading : (state, action) => {
      state.isLoading = true
    },
    settingsDataSuccessful : (state, action) => {
      state.isLoading = false
      state.data = action.payload
    },
    settingsDataFailed : (state, action) => {
      state.isLoading = false
      state.data = initialState.data
    },

    setSettingsState: (state, action) =>{
      console.log(action.payload, "item data in store");
      if (action?.payload?.length) {
        for (let i = 0; i < action.payload?.length; i++) {
          const item = action.payload[i];
          console.log(item, "item in store");
          R.assocPath(item.key, item.value, state)
        }        
      } else {
        const item = action.payload
        console.log(item, "item in store");
        R.assocPath(item.key, item.value, state)
        
      }
    }

    // updateSelectedKid(state, action) {
    //   state.details = action.payload;
    // },
  },
});

export const { setSettingsState, settingsDataFailed, settingsDataLoading, settingsDataSuccessful } = settingsSlice.actions; // creates unique actions for different reducers
export default settingsSlice.reducer;
