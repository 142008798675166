import React from "react";

const TextLink = ({
  className,
  children = "Link text",
  href = "#",
  ...props
}) => {
  return (
    <a className={[className, "block -mt-1"].join(" ")} href={href} {...props}>
      {children}
    </a>
  );
};

export default TextLink;
