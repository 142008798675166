import { getParentsSettingsFeedback } from 'services/parents.service';
import { getAuth, isAuthenticated } from '../../services/identity.service';
import { isProtected } from './Security';
import { useEffect } from 'react';
import * as R from "ramda";
import { useDispatch, useSelector } from 'react-redux';
import { settingsDataFailed, settingsDataLoading, settingsDataSuccessful } from '../store/reducers/settings';

const isBrowser = () => typeof window !== 'undefined';

const WithAuth = ({ router, children }) => {
  const {  orderConfig } =  useSelector(state => state.settings.data)
  const settingsLoading =  useSelector(state => state.settings.isLoading)
  const dispatch =  useDispatch()
  
  const fetchSettingsFeedback = async (token) => {
    dispatch(settingsDataLoading())
    const res = await getParentsSettingsFeedback(token);
    const settingsData = R.pathOr(null, ["data", "entity"], res);

    console.log(res,"========status of get setting api !");
    if (res?.data?.status) {
      dispatch(settingsDataSuccessful(settingsData))
    } else {
      dispatch(settingsDataFailed(settingsData))
    }
  }

  useEffect(() => {
    if (auth?.token && !orderConfig) {
      fetchSettingsFeedback(auth.token)
    }
  }, [auth])
  
  const auth = getAuth();
  if (isBrowser() && !isAuthenticated(auth) && isProtected(router.pathname)) {
    router.replace('/login');
    return null;
  }
  return children;
};

export default WithAuth;
